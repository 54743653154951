document.addEventListener( "turbolinks:load", function() {
	$( document ).on( "click","[data-close-element]", function() {
    closeMethod = $(this).data("close-element");
    if( closeMethod == "empty" ) {
      $(this).parent().empty();
    } else {
      $(this).parent().remove();
    }
  });
})
